import { Button, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { IFormWatcherComponentProps } from "../IFormComponentProps";
import { withLoading } from "../withLoading";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from "@mui/system";

export interface IProps {
    locations?: ILocation[];
}

interface ILocation {
    name?: string;
    days?: boolean[][];
}

const RelaxationProtocolWatcher: React.FC<IFormWatcherComponentProps<IProps>> = ({ url, onChange, value }) => {
    const [data, setData] = useState<IProps>(value);
    const [selectedLocation, setSelectedLocation] = useState<number>(0);
    const [day, setDay] = useState<number>(0);

    useEffect(() => {
        if (value.locations === undefined) {
            value.locations = [{days:[[]]}];
        }
        setData(value);
    }, [value]);

    useEffect(() => {
        onChange(url, data);
    }, [data.locations]);

    const onCheckboxChange = (value: boolean, index: number) => {
        let locations = [...data?.locations ?? [{days:[[]]}]];
        if (locations[selectedLocation].days?.[day] === undefined) { 
            locations[selectedLocation].days![day] = []
        }
        locations[selectedLocation].days![day][index] = value;
        setData({locations});
    }

    const addLocation = () => {
        let locations = [...data?.locations ?? [{days:[[]]}]];
        locations.push({days:[[]]});
        setData({locations});
        setSelectedLocation(locations.length-1)
    }

    const removeLocation = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
        e.preventDefault();
        e.stopPropagation();
        let locations = [...data?.locations ?? [{days:[[]]}]];
        locations.splice(index, 1);
        setSelectedLocation(0);
        setData({locations});
    }

    const onLocationNameChange = (name: string, index: number) => {
        let locations = [...data?.locations ?? [{days:[[]]}]];
        locations[index].name = name;
        setData({locations});
    }

    const onLocationChange = (selected: number) => {
        setSelectedLocation(selected);
        setDay(0);
    }

    const dayLabels = [
        ['Down for 5 seconds','Down for 10 seconds','Down while you take 1 step back and return','Down while you take 2 steps back and return','Down for 10 seconds','Down while you take 1 step to the right and return','Down while you take 1 step to the left and return','Down for 10 seconds','Down while you take 2 steps back and return','Down while you take 2 steps to the right and return','Down for 15 seconds','Down while you take 2 steps to the left and return','Down while you clap your hands softly once','Down while you take 3 steps back and return','Down while you count out loud to 10','Down while you clap your hands softly once','Down while you count out loud to 20','Down while you take 3 steps to the right and return','Down while you clap your hands softly twice','Down for 3 seconds','Down for 5 seconds','Down while you take 1 step back and return','Down for 3 seconds','Down for 10 seconds','Down for 5 seconds','Down for 3 seconds'],
        ['Down for 10 seconds','Down while you take 1 step back and return','Down while you take 3 steps back and return','Down for 10 seconds','Down while you take 3 steps to the right and return','Down while you take 3 steps to the left and return','Down for 10 seconds','Down while you take 3 steps to the right and clap your hands','Down while you take 3 steps to the left and clap your hands','Down for 5 seconds','Down for 10 seconds','Down while you walk one fourth of the way around the dog to the right','Down while you take 4 steps back','Down while you walk one fourth of the way around the dog to the left','Down for 10 seconds','Down while you take 5 steps back from the dog, clapping your hands, and return','Down while you walk halfway around the dog to the right and return','Down while you walk halfway around the dog to the left and return','Down for 10 seconds','Down while you jog quietly in place for 3 seconds','Down while you jog quietly in place for 5 seconds','Down while you jog quietly in place for 10 seconds','Down for 10 seconds','Down while you jog one fourth of the way around the dog to the right and return','Down while you jog one fourth of the way around the dog to the left and return','Down for 5 seconds','Down for 10 seconds'],
        ['Down for 10 seconds','Down for 15 seconds','Down while you take 2 steps backward and return','Down while you jog 5 steps backward from the dog and return','Down while you walk halfway around the dog to the right and return','Down while you walk halfway around the dog to the left and return','Down while you take 10 steps backward and return','Down for 15 seconds','Down while you take 10 steps to the left and return','Down while you take 10 steps to the right and return','Down for 20 seconds','Down while you walk halfway around the dog to the right, clapping your hands, and return','Down for 20 seconds','Down while you walk halfway around the dog to the left, clapping your hands, and return','Down for 10 seconds','Down while you jog 10 steps to the right and return','Down while you job 10 steps to the left and return','Down while you job 10 steps to the left and return','Down for 15 seconds','Down while you jog in place for 20 seconds','Down for 10 seconds','Down while you jog backward 5 steps and return','Down while you jog to the right 5 steps and return','Down while you jog to the left 5 steps and return','Down for 5 seconds while you clap your hands','Down for 10 seconds while you clap your hands','Down for 10 seconds','Down for 5 seconds'],
        ['Down for 10 seconds','Down while you jog backward 5 steps and return','Down for 20 seconds','Down while you jog halfway around the dog to the right and return','Down while you jog halfway around the dog to the left and return','Down while you move three fourths of the way around the dog to the right and return','Down while you move three fourths of the way around the dog to the left and return','Down while you jog backward 5 steps, clapping your hands, and return','Down for 10 seconds','Down while you clap your hands for 20 seconds','Down while you move quickly backward 10 steps and return','Down while you move quickly 15 steps backward and return','Down for 20 seconds','Down while you jog halfway around the dog to the right and return','Down while you jog halfway around the dog to the left and return','Down while you walk quickly 15 steps to the left and return','Down while you walk quickly 15 steps to the left and return','Down for 20 seconds','Down while you move three fourths of the way around the dog to the right and return','Down while you move three fourths of the way around the dog to the left and return','Down while you walk all the way around the dog','Down while you walk approximately 20 steps to an entrance and return','Down while you walk approximately 20 steps to an entrance, clapping your hands, and return','Down while you walk around the dog, quietly clapping your hands, and then return','Down for 20 seconds','Down while you jog quickly around the dog','Down for 20 seconds','Down for 10 seconds while you clap your hands'],
        ['Down for 5 seconds','Down for 15 seconds','Down while you walk quickly 15 steps to the right and return','Down while you walk quickly 15 steps to the left and return','Down while you walk approximately 20 steps to an entrance and return','Down while you walk approximately 20 steps to an entrance, clapping your hands, and return','Down for 20 seconds','Down while you walk around the dog, clapping your hands','Down for 20 seconds','Down for 10 seconds','Down while you walk quickly backward, clapping your hands, and return','Down while you walk approximately 20 steps to an entrance and return','Down while you walk approximately 20 steps to an entrance, clapping your hands, and return','Down while you go to an entrance and just touch the doorknob or wall and return','Down for 10 seconds','Down while you walk quickly backward, clapping your hands, and return','Down while you walk approximately 20 steps to an entrance and return','Down while you walk approximately 20 steps to an entrance, clapping your hands, and return','Down while you go to an entrance and just touch the doorknob or wall and return','Down for 20 seconds','Down while you walk approximately 20 steps to an entrance, clapping your hands, and return','Down while you go to an entrance and just touch the doorknob or wall and return','Down for 10 seconds','Down while the doorknob is touched or you move into entryway and return','Down for 10 seconds','Down for 15 seconds while you clap your hands','Down for 10 seconds while you jog in place','Down for 5 seconds'],
        ['Down for 10 seconds','Down for 20 seconds while you jog back and forth in front of the dog','Down for 15 seconds','Down while you walk approximately 20 steps to an entrance and return','Down while you walk quickly backward, clapping your hands, and return','Down while you go to an entrance and just touch the doorknob or wall and return','Down for 20 seconds while jogging','Down while you walk around the dog','Down while you walk around the dog, clapping your hands','Down for 15 seconds','Down for 20 seconds','Down for 30 seconds','Down while you walk quickly backward, clapping your hands, and return','Down while you go to an entrance and just touch the doorknob or wall and return','Down while you open the door or go into the entranceway for 5 seconds and return','Down while you open the door or go into the entranceway for 10 seconds and return','Down for 30 seconds','Down while you walk quickly backward, clapping your hands, and return','Down while you go to an entrance and just touch the doorknob or wall and return','Down for 10 seconds','Down while you go through the door or the entranceway and return','Down while you go through the door or the entranceway, clapping your hands, and return','Down while you open the door or go though the entranceway for 10 seconds and return','Down for 30 seconds','Down while you disappear from view for 5 seconds and return','Down for 20 seconds','Down for 10 seconds while you clap your hands','Down for 5 seconds'],
        ['Down for 10 seconds','Down for 20 seconds while you clap your hands','Down while you take 10 steps backward and return','Down while you walk around the dog','Down while you go through the door or the entranceway and then return','Down while you go through the door or the entranceway, clapping your hands, and return','Down while you open the door or go through the entranceway for 10 seconds and return','Down for 30 seconds','Down while you disappear from view for 5 seconds and return','Down while you go through the door or the entranceway and return','Down while you go through the door or the entranceway, clapping your hands, and return','Down while you open the door or go through the entranceway for 10 seconds and return','Down for 30 seconds','Down while you disappear from view for 10 seconds and return','Down while you disappear from view for 15 seconds and return','Down for 10 seconds','Down for 15 seconds','Down for 5 seconds while you clap your hands','Down while you jog in place for 10 seconds','Down while you jog three fourths of the way to the right and return','Down while you jog three fourths of the way to the left and return','Down while you go through the door or the entranceway, clapping your hands, and return','Down while you open the door or go through the entranceway for 10 seconds and return','Down for 30 seconds','Down while you disappear from view for 15 seconds and return','Down for 10 seconds','Down for 5 seconds'],
        ['Down for 10 seconds','Down for 15 seconds while you jog and clap your hands','Down while you take 10 steps backward and returnDown while you back up 15 steps and return','Down while you circle the dog and return','Down while you disappear from view for 20 seconds and return','Down while you disappear from view for 25 seconds and return','Down for 5 seconds','Down for 5 seconds while you down in a chair (placed 5 feet from the dog)','Down for 5 seconds','Down for 15 seconds while you jog and clap your hands','Down while you back up 15 steps and return','Down while you circle the dog and return','Down while you disappear from view for 20 seconds and return','Down while you disappear from view for 30 seconds and return','Down for 5 seconds','Down while you circle the dog and return','Down while you disappear from view for 20 seconds and return','Down while you disappear from view for 25 seconds and return','Down for 5 seconds while you down in a chair near the dog','Down while you disappear from view for 10 seconds, down in a chair for 5 seconds, and return','Down for 10 seconds','Down for 20 seconds while you jog and clap your hands','Down for 15 seconds while you run around the dog','Down for 10 seconds','Down for 5 seconds while you turn around','Down for 5 seconds while you down in a chair near the dog','Down while you disappear from view for 10 seconds, down in a chair for 5 seconds, and return','Down for 10 seconds'],
        ['Down for 5 seconds','Down for 10 seconds while you turn around','Down for 5 seconds while you jog','Down while you walk around the dog','Down while you jog around the dog','Down while you jog around the dog, clapping your hands','Down while you jog twice around the dog','Down for 10 seconds','Down for 15 seconds while you clap your hands','Down for 20 seconds','Down while you move three fourths of the way around the dog to the right and return','Down while you move three fourths of the way around the dog to the left and return','Down while you disappear from view for 10 seconds and return','Down while you circle the dog and return','Down while you disappear from view for 20 seconds and return','Down while you disappear from view for 25 seconds and return','Down for 5 seconds while you down in a chair near the dog','Down while you disappear from view for 10 seconds, down in a chair for 5 seconds, and return','Down for 10 seconds','Down while you bend down and touch your toes','Down while you stretch your arms','Down while you stretch your arms and jump once','Down while you touch your toes 5 times','Down while you stretch your arms and jump 3 times','Down for 15 seconds','Down for 10 seconds','Down for 5 seconds'],
        ['Down for 5 seconds while you clap','Down for 10 seconds while you touch your toes','Down for 15 seconds while you down in a chair','Down while you walk quickly 15 steps to the right and return','Down while you walk quickly 15 steps to the left and return','Down while you walk approximately 20 steps to an entrance and return','Down while you disappear from view for 5 seconds and return','Down while you disappear from view for 10 seconds and return','Down while you disappear from view for 15 seconds and return','Down for 10 seconds','Down for 5 seconds','Down while you walk quickly 15 steps to the right and return','Down while you walk quickly 15 steps to the left and return','Down while you approximately 20 steps to an entrance and return','Down while you disappear from view for 5 seconds and return','Down while you disappear from view for 10 seconds and return','Down while you disappear from view for 15 seconds and return','Down while you disappear from view for 5 seconds, knock softly on the wall, and return','Down for 5 seconds','Down while you disappear from view for 5 seconds and return','Down while you disappear from view for 10 seconds and return','Down while you disappear from view for 15 seconds and return','Down while you disappear from view for 5 seconds, knock softly on the wall, and return','Down while you disappear from view, knock quickly but softly on the wall, and return','Down for 5 seconds','Down while you disappear from view for 10 seconds, knock softly on the wall, and return','Down for 10 seconds','Down for 5 seconds'],
        ['Down for 5 seconds','Down for 10 seconds','Down while you disappear from view, knock quickly but softly on the wall, and return','Down for 5 seconds','Down while you disappear from view for 10 seconds, knock softly on the wall, and return','Down for 30 seconds','Down while you disappear from view, ring the doorbell, and immediately return','Down while you disappear from view, ring the doorbell, wait 2 seconds, and return','Down for 30 seconds','Down while you disappear from view, ring the doorbell, and immediately return','Down while you disappear from view, ring the doorbell, wait 5 seconds, and return','Down for 30 seconds','Down while you disappear from view, ring the doorbell, and immediately return','Down while you disappear from view, ring the doorbell, wait 10 seconds, and return','Down for 5 seconds while you jog around the dog','Down while you walk around the dog','Down while you jog around the dog','Down while you jog around the dog, clapping your hands','Down while you jog twice around the dog','Down for 10 seconds','Down for 15 seconds while you clap your hands','Down for 20 seconds','Down while you move three fourths of the way around the dog to the right and return','Down while you move three fourths of the way around the dog to the left and return','Down while you disappear from view for 10 seconds and return','Down while you circle the dog and return','Down for 10 seconds','Down for 5 seconds'],
        ['Down for 10 seconds','Down for 5 seconds while you clap your hands','Down for 15 seconds','Down for 20 seconds while you hum','Down while you disappear from view for 20 seconds and return','Down while you disappear from view for 25 seconds and return','Down for 5 seconds while you down in a chair near the dog','Down while you disappear from view for 10 seconds, down in a chair for 5 seconds, and return','Down for 15 seconds','Down for 20 seconds while you hum','Down while you disappear from view for 20 seconds and return','Down while you disappear from view for 25 seconds and return','Down while you move three fourths of the way around the dog to the right and return','Down while you move three fourths of the way around the dog to the left and return','Down while you disappear from view for 10 seconds and return','Down while you circle the dog and return','Down for 10 seconds','Down while you disappear from view, knock quickly but softly on the wall, and return','Down for 5 seconds','Down while you disappear from view for 10 seconds, knock softly on the wall, and return','Down for 30 seconds','Down while you disappear from view, ring the doorbell, and immediately return','Down while you disappear from view, ring the doorbell, wait 2 seconds, and return','Down for 30 seconds','Down while you disappear from view, say "hello," and return','Down while you disappear from view, say "hello," wait 3 seconds, return','Down for 10 seconds','Down for 5 seconds'],
        ['Down for 5 seconds','Down for 15 seconds while you hum','Down for 15 seconds while you clap your hands and hum','Down while you disappear from view for 20 seconds and return','Down while you disappear from view for 25 seconds and return','Down for 5 seconds while you down in a chair near the dog','Down while you disappear from view for 10 seconds, down in a chair for 5 seconds, and return','Down for 5 seconds','Down for 10 seconds','Down while you disappear from view, knock quickly but softly on the wall, and return','Down for 5 seconds','Down while you disappear from view for 10 seconds, knock softly on the wall, and return','Down for 30 seconds','Down while you disappear from view, ring the doorbell, and immediately return','Down while you disappear from view, ring the doorbell, wait 2 seconds, and return','Down for 30 seconds','Down while you disappear from view, say "hello," wait 5 seconds, and return','Down while you disappear from view, knock or ring the doorbell, say "hello," wait 5 seconds, and return','Down for 30 seconds','Down while you disappear from view, say "hello," wait 5 seconds, and return','Down while you disappear from view, knock or ring the doorbell, say "hello," wait 5 seconds, and return','Down for 20 seconds while you hum','Down for 15 seconds while you clap your hands','Down for 5 seconds','Down while you jog around the dog','Down for 10 seconds while you clap your hands and hum','Down for 5 seconds while you jog in place','Down while you jog around the dog, humming'],
        ['Down for 10 seconds','Down for 10 seconds','Down for 5 seconds while you clap your hands and hum','Down while you run around the dog','Down while you walk back and forth to the door','Down while you leave the room, quickly knock or ring the doorbell, and return','Down for 5 seconds','Down for 10 seconds','Down for 10 seconds','Down for 5 seconds while you clap your hands and hum','Down while you run around the dog','Down while you walk back and forth to the door','Down while you leave the room, quickly knock or ring the doorbell, and return','Down for 5 seconds','Down for 10 seconds','Down while you disappear from view for 10 seconds, knock softly on the wall, and return','Down for 30 seconds','Down while you disappear from view, ring the doorbell, and immediately return','Down while you disappear from view, ring the doorbell, wait 2 seconds, and return','Down for 30 seconds','Down while you disappear from view, say "hello," wait 5 seconds, and return','Down while you disappear from view, knock or ring the doorbell, say "hello," wait 10 seconds, return','Down for 30 seconds','Down while you disappear from view, say "hello," wait 10 seconds, and return','Down while you disappear from view, knock or ring the doorbell, say "hello," wait 10 seconds, and return','Down for 20 seconds while you hum','Down for 20 seconds','Down for 5 seconds'],
        ['Down for 10 seconds','Down for 5 seconds','Down for 15 seconds while you clap your hands and hum','Down while you disappear from view, knock or ring the doorbell, say "hello," talk for 10 seconds, and return','Down for 20 seconds while you hum','Down while you disappear from view, say "hello," invite the imaginary person in, wait 5 seconds, and return','Down for 10 seconds','Down for 5 seconds','Down while you disappear from view, say "hello," invite the imaginary person in, wait 10 seconds, and return','Down while you disappear from view, say "hello," talk (as if to someone) for 5 seconds, and return','Down for 5 seconds while you clap your hands and hum','Down while you run around the dog','Down while you walk back and forth to the door','Down while you leave the room, quickly knock or ring the doorbell, and return','Down for 5 seconds','Down while you leave the room, knock or ring the doorbell for 3 seconds, and return','Down while you leave the room and knock or ring the doorbell for 5 seconds','Down while you leave the room and talk for 3 seconds to people who are not there','Down while you leave the room and talk for 5 seconds to people who are not there','Down while you leave the room and talk for 10 seconds to people who are not there','Down while you run around the dog','Down for 10 seconds while you down in a chair','Down for 30 seconds while you down in a chair','Down for 15 seconds while you clap your hands and jog','Down for 5 seconds']
    ]

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Tabs
                    value={selectedLocation}
                    variant="scrollable"
                    scrollButtons
                    onChange={(_, value) => onLocationChange(value)}>
                    { data.locations?.map((location, index) => 
                        <Tab value={index} key={index} label={
                            <OutlinedInput 
                                placeholder={`Location ${index+1}`} 
                                label="Location" 
                                value={location.name}
                                onChange={e => onLocationNameChange(e.target.value, index)}
                                onClick={() => onLocationChange(index)}
                                endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton disabled={(data.locations?.length ?? 0) < 2} onClick={e => removeLocation(e, index)}>
                                        <DeleteIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  } />} />)}
                    <Button onClick={addLocation}><AddIcon/></Button>
                </Tabs>
            </Grid>
            <Grid item xs={12}>
                <FormGroup>
                    <FormControl fullWidth>
                        <InputLabel>Relaxation Protocol Day</InputLabel>
                        <Select
                            value={day}
                            label="Relaxation Protocol Day"
                            onChange={e => setDay(Number(e.target.value))}
                        >
                            { dayLabels.map((_, index) => <MenuItem key={index} value={index}><Chip label={data.locations?.[selectedLocation].name ?? `Location ${selectedLocation+1}`}/><span style={{marginLeft:'10px'}}>Day {index+1}</span></MenuItem>)}
                        </Select>
                    </FormControl>
                    { dayLabels[day].map((label, index) =>
                        <FormControlLabel key={index} control={
                            <Checkbox 
                                checked={data.locations?.[selectedLocation].days?.[day]?.[index] ?? false}
                                onChange={e => onCheckboxChange(e.target.checked, index)} />} 
                            label={<span style={{textDecoration:data.locations?.[selectedLocation].days?.[day]?.[index] ?? false ? 'line-through' : 'none'}}>{label}</span>} />
                    )}
                </FormGroup>
            </Grid>
        </Grid>
    )
}

export const RelaxationProtocol = withLoading(RelaxationProtocolWatcher);