import { Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { styles } from "../../../App.styles";
import { IFormWatcherComponentProps } from "../../IFormComponentProps";
import { withLoading } from "../../withLoading";

export interface IProps {
    activity?: string;
    emotion?: string;
    bodyLanguage?: string;
}

const AssignmentWatcher: React.FC<IFormWatcherComponentProps<IProps>> = ({ url, onChange, value }) => {
    const [data, setData] = useState<IProps>(value);

    useEffect(() => {
        setData(value);
    }, [value]);

    useEffect(() => {
        onChange(url, data);
    }, [data]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <TextField
                    label="Activity"
                    placeholder="Enter the activities.."
                    onChange={e => setData({ ...data, activity: e.target.value })} 
                    value={data.activity || ''}
                    fullWidth
                    multiline
                    minRows={15}
                    variant="filled"
                    InputProps={{ style: styles.blanksField}}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    label="Emotion"
                    placeholder="What emotion do you feel is driving the behaviour?"
                    fullWidth
                    onChange={e => setData({ ...data, emotion: e.target.value })} 
                    value={data.emotion || ''}
                    multiline
                    minRows={15}
                    variant="filled"
                    InputProps={{ style: styles.blanksField}}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    label="Body Language"
                    placeholder="What body language are they displaying?"
                    fullWidth
                    onChange={e => setData({ ...data, bodyLanguage: e.target.value })} 
                    value={data.bodyLanguage || ''}
                    multiline
                    minRows={15}
                    variant="filled"
                    InputProps={{ style: styles.blanksField}}
                />
            </Grid>
        </Grid>
    )
}

export const Day1Assignment = withLoading(AssignmentWatcher);