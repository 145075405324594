import {
    HashRouter,
} from "react-router-dom";
import { FormRoutes } from "./components/FormRoutes";

function App() {
    return (
        <HashRouter>
            <FormRoutes/>
        </HashRouter>
    );
}

export default App;