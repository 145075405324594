import { TextField } from "@mui/material"
import { useEffect, useState } from "react";
import { styles } from "../../../App.styles";
import { BlankLine } from "../../BlankLine/BlankLine";
import { IFormWatcherComponentProps } from "../../IFormComponentProps";
import { withLoading } from "../../withLoading";

interface IProps {
    one?: string;
    two?: string;
    three?: string;
    four?: string;
    five?: string;
    six?: string;
}

const ManagementWatcher: React.FC<IFormWatcherComponentProps<IProps>> = ({url, onChange, value}) => {
    const [data, setData] = useState<IProps>(value);

    useEffect(() => {
        setData(value);
    }, [value]);

    useEffect(() => {
        onChange(url, data);
    }, [data]);

    return (
        <>
            <BlankLine text="Control and management is dog trainer speak for setting up the" />
            <TextField onChange={e => setData({ ...data, one: e.target.value })} value={data.one || ''} variant="standard" InputProps={{ style: styles.blanksField}}/>
            <BlankLine text="to" />
            <TextField onChange={e => setData({ ...data, two: e.target.value })} value={data.two || ''} variant="standard" InputProps={{ style: styles.blanksField}} />
            <BlankLine text="what you don't want and increasing the likelihood of the behaviour you" />
            <TextField onChange={e => setData({ ...data, three: e.target.value })} value={data.three || ''} variant="standard" InputProps={{ style: styles.blanksField}} />
            <BlankLine text={<>want. Practice makes</>} />
            <TextField onChange={e => setData({ ...data, four: e.target.value })} value={data.four || ''} variant="standard" InputProps={{ style: styles.blanksField}} />
            <br/>
            <br/>
            <BlankLine text="Set your dog up for" />
            <TextField onChange={e => setData({ ...data, five: e.target.value })} value={data.five || ''} variant="standard" InputProps={{ style: styles.blanksField}} />
            <BlankLine text="by ensuring good" />
            <TextField onChange={e => setData({ ...data, six: e.target.value })} value={data.six || ''} variant="standard" InputProps={{ style: styles.blanksField}} />
            <BlankLine text="is in place." />
        </>
    )
}

export const Management = withLoading(ManagementWatcher);