import { TextField } from "@mui/material"
import { useEffect, useState } from "react";
import { styles } from "../../../App.styles";
import { BlankLine } from "../../BlankLine/BlankLine";
import { IFormWatcherComponentProps } from "../../IFormComponentProps";
import { withLoading } from "../../withLoading";

interface IProps {
    one?: string;
    two?: string;
    three?: string;
    four?: string;
    five?: string;
}

const BlanksWatcher: React.FC<IFormWatcherComponentProps<IProps>> = ({url, onChange, value}) => {
    const [data, setData] = useState<IProps>(value);

    useEffect(() => {
        setData(value);
    }, [value]);

    useEffect(() => {
        onChange(url, data);
    }, [data]);

    return (
        <>
            <BlankLine text="If a" />
            <TextField onChange={e => setData({ ...data, one: e.target.value })} value={data.one || ''} variant="standard" InputProps={{ style: styles.blanksField}} />
            <BlankLine text={<>dog can do what you're asking, it is not a behaviour. M.E.B. stands for <br/>M</>} />
            <TextField onChange={e => setData({ ...data, two: e.target.value })} value={data.two || ''} variant="standard" InputProps={{ style: styles.blanksField}} />
            <BlankLine text={<><br/>E</>} />
            <TextField onChange={e => setData({ ...data, three: e.target.value })} value={data.three || ''} variant="standard" InputProps={{ style: styles.blanksField}} />
            <BlankLine text={<><br/>B</>} />
            <TextField onChange={e => setData({ ...data, four: e.target.value })} value={data.four || ''} variant="standard" InputProps={{ style: styles.blanksField}}/>
            <br/>
            <br/>
            <BlankLine text="If we want our dogs to stop" />
            <TextField onChange={e => setData({ ...data, five: e.target.value })} value={data.five || ''} variant="standard" InputProps={{ style: styles.blanksField}}/>
            <BlankLine text=", we need to make sure they know what to do instead." />
        </>
    )
}

export const Day4Blanks = withLoading(BlanksWatcher);