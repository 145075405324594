import { TextField } from "@mui/material"
import { useEffect, useState } from "react";
import { styles } from "../../../App.styles";
import { BlankLine } from "../../BlankLine/BlankLine";
import { IFormWatcherComponentProps } from "../../IFormComponentProps";
import { withLoading } from "../../withLoading";

interface IProps {
    one?: string;
    two?: string;
    three?: string;
    four?: string;
    five?: string;
    six?: string;
}

const BlanksWatcher: React.FC<IFormWatcherComponentProps<IProps>> = ({ url, onChange, value }) => {
    const [data, setData] = useState<IProps>(value);

    useEffect(() => {
        setData(value);
    }, [value]);

    useEffect(() => {
        onChange(url, data);
    }, [data]);

    return (
        <>
            <TextField onChange={e => setData({ ...data, one: e.target.value })} value={data.one || ''} variant="standard" InputProps={{ style: styles.blanksField}} />
            <BlankLine text="is the behaviour seen in most animals that when an animal is offered a choice between free food or identical food that requires effort, the animal prefers the food that requires" />
            <TextField onChange={e => setData({ ...data, two: e.target.value })} value={data.two || ''} variant="standard" InputProps={{ style: styles.blanksField}} />
            <br/><br/>
            <BlankLine text="The simple act of foraging in and of itself can be more" />
            <TextField onChange={e => setData({ ...data, three: e.target.value })} value={data.three || ''} variant="standard" InputProps={{ style: styles.blanksField}} />
            <BlankLine text="for your dog than simply eating the food." />
            <br/>
            <BlankLine text="P" />
            <TextField onChange={e => setData({ ...data, four: e.target.value })} value={data.four || ''} variant="standard" InputProps={{ style: styles.blanksField}} />
            <br/>
            <BlankLine text="M" />
            <TextField onChange={e => setData({ ...data, five: e.target.value })} value={data.five || ''} variant="standard" InputProps={{ style: styles.blanksField}}/>
            <br/>
            <BlankLine text="S" />
            <TextField onChange={e => setData({ ...data, six: e.target.value })} value={data.six || ''} variant="standard" InputProps={{ style: styles.blanksField}}/>
            <br/>
            <BlankLine text=" - when a dog's needs are not being met (genetic, environmental, individual), this leads to behaviours labelled compulsive, frantic or aggressive. To prevent this, we must provide opportunities for our dogs to practice their breed specific behaviours." />
        </>
    )
}

export const Day5Blanks = withLoading(BlanksWatcher);