import debounce from "lodash/debounce";
import { load } from "recaptcha-v3";

export const debouncedSave = 
    debounce(async (url: string, token: string, data: any) => {
        const recaptcha = await load('6LeOSrMeAAAAADWpX9KF9zIuvzUxAjRdC0EIecEU');
        const captcha = await recaptcha.execute('submit');
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Authorization': token }
        };
        await fetch(`${url}/${captcha}`, requestOptions);
    }, 500);