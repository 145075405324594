import { TextField } from "@mui/material"
import { useEffect, useState } from "react";
import { styles } from "../../../App.styles";
import { BlankLine } from "../../BlankLine/BlankLine";
import { IFormWatcherComponentProps } from "../../IFormComponentProps";
import { withLoading } from "../../withLoading";

interface IProps {
    one?: string;
    two?: string;
    three?: string;
}

const BlanksWatcher: React.FC<IFormWatcherComponentProps<IProps>> = ({ url, onChange, value }) => {
    const [data, setData] = useState<IProps>(value);

    useEffect(() => {
        setData(value);
    }, [value]);

    useEffect(() => {
        onChange(url, data);
    }, [data]);

    return (
        <>
            <BlankLine text="Retrievers were bred for" />
            <TextField onChange={e => setData({ ...data, one: e.target.value })} value={data.one || ''} variant="standard" InputProps={{ style: styles.blanksField}} />
            <BlankLine text="and have a typically" />
            <TextField onChange={e => setData({ ...data, two: e.target.value })} value={data.two || ''} variant="standard" InputProps={{ style: styles.blanksField}} />
            <BlankLine text="mouth. They love to" />
            <TextField onChange={e => setData({ ...data, three: e.target.value })} value={data.three || ''} variant="standard" InputProps={{ style: styles.blanksField}} />
            <BlankLine text="things." />
        </>
    )
}

export const Blanks = withLoading(BlanksWatcher);