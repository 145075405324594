import { Route, Routes } from "react-router-dom";
import { Day1Assignment } from "./BBChallenge/Day1/Assignment";
import { Blanks } from "./BBChallenge/Day1/Blanks";
import { Day2Assignment } from "./BBChallenge/Day2/Assignment";
import { Management } from "./BBChallenge/Day2/Management";
import { Day3Assignment } from "./BBChallenge/Day3/Assignment";
import { Rewards } from "./BBChallenge/Day3/Rewards";
import { Day4Assignment } from "./BBChallenge/Day4/Assignment";
import { Day4Blanks } from "./BBChallenge/Day4/Blanks";
import { Day5Blanks } from "./BBChallenge/Day5/Blanks";
import { debouncedSave } from "./debouncedSave";
import { RelaxationProtocol } from "./RelaxationProtocol/RelaxationProtocol";

export const FormRoutes = () => {
    const getData = async <T,>(url: string, token: string) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': token }
        };
        return (await fetch(url, requestOptions)
            .then(response => {
                return response.status === 200
                    ? response.json()
                    : {};
            })) as T;
    }

    const onChange = async (url: string, token: string, data: any) => {
        JSON.stringify(data) !== '{}' && await debouncedSave(url, token, data);
    }

    const getUrl = (key: string) => {
        const url = `https://goodasgoldenapi.azurewebsites.net/api/OwnedDataApi/${key}`;
        //const url = `http://localhost:7071/api/OwnedDataApi/${key}`;
        return url;
    }

    return (
        <Routes>
            <Route path="/boisterous-to-brilliant-challenge/day1/assignment/:token" element={<Day1Assignment onChange={onChange} getData={getData} url={getUrl('BBChallenge-Day1-Assignment')} />}/>
            <Route path="/boisterous-to-brilliant-challenge/day1/blanks/:token" element={<Blanks onChange={onChange} getData={getData} url={getUrl('BBChallenge-Day1-Blanks')} />}></Route>
            <Route path="/boisterous-to-brilliant-challenge/day2/management/:token" element={<Management onChange={onChange} getData={getData} url={getUrl('BBChallenge-Day2-Management')} />}></Route>
            <Route path="/boisterous-to-brilliant-challenge/day2/assignment/:token" element={<Day2Assignment onChange={onChange} getData={getData} url={getUrl('BBChallenge-Day2-Assignment')} />}></Route>
            <Route path="/boisterous-to-brilliant-challenge/day3/rewards/:token" element={<Rewards onChange={onChange} getData={getData} url={getUrl('BBChallenge-Day3-Rewards')} />}></Route>
            <Route path="/boisterous-to-brilliant-challenge/day3/assignment/:token" element={<Day3Assignment onChange={onChange} getData={getData} url={getUrl('BBChallenge-Day3-Assignment')} />}></Route>
            <Route path="/boisterous-to-brilliant-challenge/day4/blanks/:token" element={<Day4Blanks onChange={onChange} getData={getData} url={getUrl('BBChallenge-Day4-Blanks')} />}></Route>
            <Route path="/boisterous-to-brilliant-challenge/day4/assignment/:token" element={<Day4Assignment onChange={onChange} getData={getData} url={getUrl('BBChallenge-Day4-Assignment')} />}></Route>
            <Route path="/boisterous-to-brilliant-challenge/day5/blanks/:token" element={<Day5Blanks onChange={onChange} getData={getData} url={getUrl('BBChallenge-Day5-Blanks')} />}></Route>
            <Route path="/relaxation-protocol/:token" element={<RelaxationProtocol onChange={onChange} getData={getData} url={getUrl('Relaxation-Protocol')} />}></Route>
        </Routes>
    );
}