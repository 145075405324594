import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IFormComponentProps, IFormWatcherComponentProps } from "./IFormComponentProps";
import { Loading } from "./Loading/Loading";

export const withLoading = <P extends object>
    (Component: React.ComponentType<IFormWatcherComponentProps<P>>): 
        React.FC<IFormComponentProps<P>> => ({
    url, onChange, getData
}) => {
    const [data, setData] = useState<P | undefined>();
    const { token } = useParams();

    useEffect(() => {
        loadData();
    }, [token]);

    const loadData = async () => {
        setData(await getData(url, token ?? ''));
    }

    const onChangeWithAuth = (url: string, data: P) => {
        onChange(url, token ?? '', data);
    }

    return data
        ? <Component url={url} onChange={onChangeWithAuth} value={data} />
        : <Loading />
}