import { Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { styles } from "../../../App.styles";
import { IFormWatcherComponentProps } from "../../IFormComponentProps";
import { withLoading } from "../../withLoading";

export interface IProps {
    loves?: string;
    preference?: string;
}

const AssignmentWatcher: React.FC<IFormWatcherComponentProps<IProps>> = ({ url, onChange, value }) => {
    const [data, setData] = useState<IProps>(value);

    useEffect(() => {
        setData(value);
    }, [value]);

    useEffect(() => {
        onChange(url, data);
    }, [data]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextField
                    label="My Dog Loves"
                    placeholder="Enter the things your dog enjoys.."
                    onChange={e => setData({ ...data, loves: e.target.value })} 
                    value={data.loves || ''}
                    fullWidth
                    multiline
                    minRows={15}
                    variant="filled"
                    InputProps={{ style: styles.blanksField}}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label="Order of Preference"
                    placeholder="List the things your dog loves in order of preference"
                    fullWidth
                    onChange={e => setData({ ...data, preference: e.target.value })} 
                    value={data.preference || ''}
                    multiline
                    minRows={15}
                    variant="filled"
                    InputProps={{ style: styles.blanksField}}
                />
            </Grid>
        </Grid>
    )
}

export const Day3Assignment = withLoading(AssignmentWatcher);